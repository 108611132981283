<template>

  <!-- id : contents : (S) -->
  <div id='contents' class="st-mypage" >

    <section class="content-wrap st-center">
      <div class="secPageTabBox " :class="{open : this.$store.state.tapOpen === true}">
        <div class="btn-go-back mb-only"><a href="#" @click.prevent="goback">이전페이지</a></div>
        <div class="sub-tab-mb-title">
          <a href="javascript:;" class="on" @click="onClickTabMobile()" >모임 내역 <i class="arrow"></i></a> 
        </div>
        <div class="sub-tab-list-box" :class="{on:this.tablistOpen === true}">
          <a href="javascript:;"><router-link to="/my/myInfo">회원정보</router-link> </a>
          <a href="javascript:;"><router-link to="/my/meetList">모임 내역</router-link></a>
          <a href="javascript:;"><router-link to="/my/videoConference">화상 회의</router-link></a>
          <a href="javascript:;"><router-link to="/my/board">내 게시글</router-link></a>
          <a href="javascript:;"><router-link to="/my/message">쪽지함</router-link></a>
          <a href="javascript:;"><router-link to="/my/inquiry">1:1 문의</router-link></a>
          <a href="javascript:;" @click="logout">로그아웃</a>
        </div>
      </div>
      <!-- class : main-wrap (S) -->
      <div class="main-wrap">
        <div class="inner-base-box mb-pd-none">

          <div class="mypage-wrap">
            <div class="aside-box">
              <div class="people-info-box">
                <div class="img-box">
                  <img class="member-list-box" v-if="userDetail.photoFile==null" src="../../assets/front/images/icon/member.jpg"/>
                  <img class="member-list-box" v-else :src="`/attachment/user/photo/${userDetail.photoFile}`" @error="(e) => e.target.src=require('@/assets/front/images/icon/member.jpg')"/>
              </div>
              <div class="name-box">
                <div class="name"><em>{{ userDetail.name }}</em><span>님</span></div>
                <div class="cate">{{ userDetailDepartment }} / {{ memberClub(userDetail.clubGroup) }}</div>
                </div>
                <div class="btn-box">
                  <router-link to="/my/message">쪽지함</router-link>
                  <router-link to="/my/inquiry">1:1 문의</router-link>
                </div>
              </div>
              <div class="menu-box">
                <ul>
                  <li><router-link to="/my/myInfo">회원정보</router-link></li>
                  <li><router-link to="/my/inquiry">활동 내역</router-link>
                    <ul>
                      <li><router-link to="/my/inquiry">1:1 문의</router-link></li>
                      <li><router-link to="/my/board">내 게시글</router-link></li>
                      <li><router-link to="/my/message">쪽지함</router-link></li>
                    </ul>
                  </li>
                  <li class="on"><a href="javascript:;" @click="onClickMenu">모임 내역</a></li>
                  <li><router-link to="/my/videoConference">화상 회의</router-link>
                    <ul>
                      <li><router-link to="/my/videoConference">전체 회의</router-link></li>
                      <li><router-link to="/my/myConference">내가 개설한 회의</router-link></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div class="main-body-box">
              <div class="my-tit-box">모임 내역</div>
              <div class="board-search-wrap st3">
                <div class="search-box">
                  <select v-model="searchColumn" class="input-st1 st-selectbox wd150" style="height: 40px;line-height: 38px;">
                    <option value="title">제목</option>
                    <option value="meetingContents">내용</option>
                  </select>
                  <input type="text" v-model="searchKeyword" placeholder="검색어를 입력해주세요." class="input-st1 wd280 dg-ml-5" style="height: 40px;line-height: 38px;" @keydown="onKeydown">
                  <a href="javascript:;" @click="onClickSearch" class="btn-s dg-ml-5" style="height: 40px;line-height: 38px;">검색</a>
                </div>
              </div>
              <div class="tb-01 st2 st-over">
                <table>
                  <colgroup><col style="width:70px"><col style="width:110px"><col style="width:*"><col style="width:120px"><col style="width:90px"></colgroup>
                  <thead>
                  <tr>
                    <th>번호</th>
                    <th>구분</th>
                    <th>제목</th>
                    <th>신청일</th>
                    <th>상태</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(meet, idx) in meetList.content" :key="idx" class="st-board">
                    <td class="al-center pc-only">{{ pagination.totalElements - ((pagination.currentPage-1) * pagination.pageSize) - idx }}</td>
                    <td class="al-center pc-only">{{ meet.groupName }}</td>
                    <td class=" pc-only ">
                      <a href="javascript:;" class="ellipsis_inline wd560" @click="onClickTitle(meet)">{{ meet.title }}</a>
                    </td>
                    <td class="al-center pc-only">{{ formatDate(meet.joinDt) }}</td>
                    <td class="al-center pc-only">{{ formatStatus(meet.status, meet.completed, meet.attendance) }}</td>
                    <td class="mb-only">
                      <div class="tb-top">
                        <div class="tb-title">
                          <a href="javascript:;" class="ellipsis_inline wd560" @click="onClickTitle(meet)">{{ meet.title }}</a>
                        </div>
                      </div>
                      <div class="tb-btm">
                        <div class="tb-notice">{{ meet.groupName }}</div>
                        <div class="send-msg-menu-box tb-name"  > 
                          {{ formatStatus(meet.status, meet.completed, meet.attendance) }}
                        </div> 
                        <div class="tb-date">
                          {{ formatDate(meet.joinDt) }}
                        </div> 
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <!-- class : pagination (S) -->
              <DgPagination v-model="pagination" @pageSelected="pageSelected"/>
              <!-- class : pagination (E) -->
            </div>
          </div>

        </div>
      </div>
      <!-- class : main-wrap (E) -->


    </section>
    <!-- class : content-wrap : (E) -->
  </div>
  <!-- id : contents : (E) -->


</template>

<script>
import userApi from "@/api/UserApi";
import userMeetingApi from "@/api/UserMeetingApi";
import moment from "moment";
import DgPagination from "@/views/component/DgPagination";

export default {
  name: "meet_list",
  components:{
    DgPagination
  },
  data() {
    return {
      userId: this.$store.state.user.userid,
      userDetail: {},
      userDetailDepartment: '',

      meetList: [],
      pagination:{
        currentPage: 1,
        pageSize: 10,
        pageGroupSize: 10,
        totalPages:0,
        totalElements:0
      },

      searchColumn:'title',
      searchKeyword:'',
      tablistOpen:false,
    }
  },
  watch:{
    selectCheckBox(){
      let checkSize = this.pagination.totalElements < this.pagination.pageSize? this.pagination.totalElements : this.pagination.pageSize;
      if(this.selectCheckBox.length == checkSize){
        this.checkAll = true
      }
    }
  },
  created() {
    if(this.userId == '') {
      alert('로그인 후 이용하실 수 있습니다.');
      this.$router.push(`/login`);
    }else{
      this.getUserDetail();
      this.onClickSearch();
    }
  },
  methods: {
    getUserDetail() {
      userApi.get(this.userId).then((data) => {
        this.userDetail = data
        this.userDetailDepartment = data.departmentGroup[0].groupName
      })
    },
    memberClub(val) {
      if (val != null) {
        let clubLength = val.length
        let userClubs = clubLength > 0 ? val[0].groupName : ''
        if (clubLength > 1) {
          userClubs += " 외 " + (clubLength - 1)
        }
        return userClubs
      } else {
        return '';
      }
    },
    onClickSearch() {
      let param = {
        size: this.pagination.pageSize,
        page: this.pagination.currentPage,
        searchColumn: this.searchColumn,
        searchKeyword: this.searchKeyword
      }
      userMeetingApi.list(this.userId, param).then(data => {
        // console.log(JSON.stringify(data));
        this.meetList = data;

        this.pagination.totalElements = data.totalElements;
        let totalPages = Math.floor(data.totalElements / this.pagination.pageSize) + ((data.totalElements % this.pagination.pageSize) > 0 ? 1 : 0);
        this.pagination.totalPages = totalPages;
      })
    },
    formatDate(value) {
      return moment(value).format('YYYY.MM.DD');
    },
    pageSelected(pagination) {
      this.pagination.currentPage = pagination.currentPage;
      this.checkAll = false;
      this.selectCheckBox = [];
      this.onClickSearch();
    },
    onClickTitle(meet) {
      this.$router.push(`/group/schedule?articleId=` + meet.meetingId + `&upper=431&menu=488`)
    },
    onKeydown(e) {
      if (e.keyCode === 13) {
        this.onClickSearch();
      }
    },
    onClickMenu() {
      this.meetList= [],
      this.pagination= {
        currentPage: 1,
        pageSize: 10,
        pageGroupSize: 10,
        totalPages: 0,
        totalElements: 0
      },

      this.searchColumn='title';
      this.searchKeyword='';

      this.onClickSearch();
    },
    formatStatus(status, isComplete, isAttendence){
      if(!isAttendence || status == 'STATE_CANCEL'){
        return '취소';
      }else if(isComplete){
        return '완료';
      }else {
        return '진행';
      }
    },
    onClickTabMobile(){
      if(this.tablistOpen){
        this.tablistOpen = false;
      }else{
        this.tablistOpen = true;
      }
    },
    goback(){
      this.$router.go(-1);
    },
    logout() {
      this.$store.dispatch('user/logout');
      window.location.href='/'
    },
  }
}
</script>

<style scoped>

</style>