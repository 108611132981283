import axios from 'axios';
import {clarify} from "../utils/clear";

class UserMeetingApi {
    constructor() {
        this.endpoint = process.env.VUE_APP_BASE_API;
    }


    list(userId, body) {
        const params = new URLSearchParams(clarify(body));
        return axios.get(`${this.endpoint}/group/user/${userId}/meetings`, {params}).then(response=>response.data);
    }

    delete(userId, meetingId) {
        return axios.delete(`${this.endpoint}/group/user/${userId}/meetings/${meetingId}/delete`, {}).then(response=>response.data);
    }

}


export default new UserMeetingApi();
